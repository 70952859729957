<template>
  <v-card flat class="w-100 d-flex">
    <GoogleMap
      :api-key="apiKey"
      style="width: 100%; min-height: 900px"
      :center="center"
      :zoom="13"
    >
      <Marker :options="markerOptions">
        <InfoWindow v-model="infowindow">
          <h2>{{ nickname }} Warehouse</h2>
          <div id="content">{{ address }}</div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";

const props = defineProps<{
  center: Object;
  markerOptions: Object;
  nickname: string;
  address: string;
}>();

const infowindow = ref(true);
const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
</script>
