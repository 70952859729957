<script setup lang="ts">
import { userStore } from "@/stores";
import { defineProps } from "vue";

interface DockDoor {
  id: number;
  name: string;
  nickname: string;
  active: boolean;
  deleted_at?: Date | null;
}

interface DockDoorsData {
  data: DockDoor[];
  total_records: number;
}

const props = defineProps<{
  dockDoors: DockDoorsData;
  deleteDockDoor: (dockDoor: DockDoor) => void;
  restoreDoor: (dockDoor: DockDoor) => void;
}>();

const headers = [
  {
    title: "Name",
    value: "name",
    sortable: true,
  },
  {
    title: "Nickname",
    value: "nickname",
    sortable: true,
  },
  {
    title: "Status",
    value: "active",
    sortable: true,
  },
  {
    title: "Actions",
    value: "actions",
    sortable: false,
  },
];

const deleteDoor = (dockDoor: DockDoor): void => {
  props.deleteDockDoor(dockDoor);
};
</script>

<template>
  <div v-if="dockDoors && dockDoors.data" class="w-100 mt-2">
    <v-card-title class="pb-0">{{ dockDoors.total_records }} Dock Doors</v-card-title>
    <v-card-text class="w-100">
      <v-data-table
        :headers="headers"
        :items="dockDoors.data"
        class="elevation-1"
        item-key="id"
      >
        <template v-slot:item.active="{ item }">
          <v-chip
            size="small"
            :color="item.deleted_at ? 'grey' : 'success'"
            :prepend-icon="item.deleted_at ? 'mdi-close' : 'mdi-check'"
          >
            {{ item.deleted_at ? "Inactive" : "Active" }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="!item.deleted_at && userStore.hasPermissionTo('update_warehouse')"
            variant="text"
            color="red"
            @click="deleteDoor(item)"
            >Delete</v-btn
          >
          <v-btn
            v-if="item.deleted_at && userStore.hasPermissionTo('update_warehouse')"
            variant="text"
            color="indigo-lighten-2"
            @click="restoreDoor(item)"
            >Restore</v-btn
          >
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>
