<script setup lang="ts">
import { ref, onMounted, watch, Ref, nextTick } from "vue";
import { useUserStore } from "@/stores/user";
// @ts-ignore
import useWarehouses from "@/hooks/useWarehouses";
import { useRoute } from "vue-router";
import { setPageTitle } from "@/utils/pageTitle";

const route = useRoute();
const menu = ref(false);
const userStore = useUserStore();
const showDate: Ref<number | null> = ref(null);
const closures = ref({});
const closureDate = ref(new Date(Date.now()));
const closureType = ref(); // Default to 'ib_slot'
const closureTimes: Ref<string[]> = ref([]);
const ibSlots: Ref<string[]> = ref([]);
const obSlots: Ref<string[]> = ref([]);
const closureOptions = ref(["ALL", ...ibSlots.value]);
const formattedClosureDate = ref("");

const {
  warehouse,
  removeClosure,
  fetchWarehouse,
  updateClosure,
  manageClosures,
  transformSlotsData,
  fetchWarehouseDockDoors,
} = useWarehouses();

onMounted(async () => {
  await fetchAndSetWarehouseData();
});

watch(
  () => warehouse.value,
  (newValue) => {
    if (newValue && newValue.data) {
      ibSlots.value = newValue.data.appt_settings?.ib_slots?.sort();
      obSlots.value = newValue.data.appt_settings?.ob_slots?.sort();
    }
  },
  { deep: true }
);

watch(closureType, (newValue) => {
  if (newValue == "ib_slot") {
    closureOptions.value = ["ALL", ...ibSlots.value];
  } else {
    closureOptions.value = ["ALL", ...obSlots.value];
  }
});

const fetchAndSetWarehouseData = async () => {
  await fetchWarehouse(route.params.id).then((response: any) => {
    setPageTitle(
      `${response.data.nickname} (${response.data.address_entry?.full_address}) Warehouse`
    );
    ibSlots.value = response.data.appt_settings?.ib_slots?.sort();
    obSlots.value = response.data.appt_settings?.ob_slots?.sort();

    const transformedSlots = transformSlotsData(response.data.appt_settings?.closures);
    closures.value = transformedSlots;
  });
  await fetchWarehouseDockDoors(route.params.id);
};

const handleShowDate = (date: number) => {
  if (showDate.value == date) {
    showDate.value = null;
  } else {
    showDate.value = date;
  }
};

const handleDateIcon = (date: number) => {
  if (showDate.value == date) {
    return "mdi-arrow-up";
  } else {
    return "mdi-arrow-down";
  }
};

const handleRemoveClosure = async (date: number, type: string) => {
  let confirmed = confirm(`Are you sure you want to delete ${date}?`);
  if (!confirmed) {
    return;
  }
  const closureData = {
    id: warehouse.value.id,
    type: type,
    date,
  };

  await removeClosure(route.params.id, closureData);
  await fetchAndSetWarehouseData();
};

const manageClosure = async () => {
  const closureData = {
    id: warehouse.value.id,
    type: closureType.value,
    dates: {
      [formatDate(closureDate.value)]: Array.isArray(closureTimes.value)
        ? closureTimes.value
        : [closureTimes.value],
    },
  };

  await manageClosures(route.params.id, closureData);
  await fetchAndSetWarehouseData();
};

watch(closureDate, (newValue) => {
  if (newValue) {
    const date = new Date(newValue);
    formattedClosureDate.value = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  }
});

watch(
  closureTimes,
  async (newValue, oldValue) => {
    const allSelected = newValue.includes("ALL");
    const otherOptionsSelected = newValue.some((option) => option !== "ALL");

    // If 'ALL' is selected along with other options, reset to only 'ALL'
    if (allSelected && otherOptionsSelected) {
      // Use nextTick to ensure reactivity
      await nextTick();
      closureTimes.value = ["ALL"];
    }
    // If 'ALL' is deselected, just allow the update to proceed
  },
  { deep: true }
);

const formatDate = (val: any) => {
  const date = new Date(val);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
    date.getDate()
  ).padStart(2, "0")}`;
};
</script>

<template>
  <v-card
    flat
    v-for="(data, date) in closures"
    class="px-4 border-b border-grey-lighten-4"
  >
    <v-card-title
      @click.stop="handleShowDate(date)"
      class="w-100 d-flex align-center justify-space-between"
    >
      {{ date }}

      <v-icon color="primary" @click.stop="handleShowDate(date)">{{
        handleDateIcon(date)
      }}</v-icon>
    </v-card-title>
    <div class="d-flex justify-end py-2" v-if="showDate == date">
      <v-btn
        flat
        prepend-icon="mdi-delete"
        color="error"
        @click="() => handleRemoveClosure(date, 'ib_slot')"
        >Delete Date</v-btn
      >
    </div>

    <div
      class="d-flex justify-center px-8 bg-grey-lighten-4 mb-4"
      v-if="showDate == date"
    >
      <v-list class="w-50 mr-8 bg-grey-lighten-4">
        <v-list-item-title class="font-weight-bold">Inbound Slots</v-list-item-title>
        <v-list-item v-for="n in data.ib" :key="n" :title="n" />
      </v-list>
      <v-list class="w-50 mr-8 bg-grey-lighten-4">
        <v-list-item-title class="font-weight-bold">Outbound Slots</v-list-item-title>
        <v-list-item v-for="n in data.ob" :key="n" :title="n" />
      </v-list>
    </div>
  </v-card>

  <v-card class="mt-4" v-if="userStore.hasPermissionTo('update_warehouse')">
    <v-container>
      <v-row>
        <v-card-title>Update Closures</v-card-title>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            id="menu-activator"
            v-model="formattedClosureDate"
            label="Closure Date"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            activator="#menu-activator"
          >
            <v-date-picker
              update:modelValue="() => {}"
              show-adjacent-months
              v-model="closureDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="closureType"
            :items="['ib_slot', 'ob_slot']"
            label="Slot Type"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <!-- Time selection logic goes here -->
          <v-select
            v-model="closureTimes"
            :items="closureOptions"
            label="Closure Times"
            multiple
            chips
            clearable
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-btn color="primary" @click="manageClosure">Update Closure</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
