<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { setPageTitle } from "@/utils/pageTitle";
// @ts-ignore
import useWarehouses from "@/hooks/useWarehouses";
// @ts-ignore
import { useRoute } from "vue-router/auto";
import WarehouseMap from "@/components/warehouse/WarehouseMap.vue";
import WarehouseDockDoorsTable from "@/components/warehouse/WarehouseDockDoorsTable.vue";
import WarehouseClosures from "@/components/warehouse/WarehouseClosures.vue";
import { useRouter } from "vue-router";
import WarehouseAppointmentSlots from "@/components/warehouse/WarehouseAppointmentSlots.vue";

const {
  warehouse,
  fetchWarehouse,
  fetchWarehouseDockDoors,
  dockDoors,
  deleteDockDoor,
  restoreDockDoor,
} = useWarehouses();
const route = useRoute();
const center = ref({});
const markerOptions = ref({});
const tab = ref("one");
const router = useRouter();

onMounted(async () => {
  await fetchAndSetWarehouseData();
});

const fetchAndSetWarehouseData = async () => {
  await fetchWarehouse(route.params.id).then((response: any) => {
    setPageTitle(
      `${response.data.nickname} (${response.data.address_entry?.full_address}) Warehouse`
    );
    center.value = {
      lat: response.data.address_entry?.latitude,
      lng: response.data.address_entry?.longitude,
    };
    markerOptions.value = {
      position: center.value,
      label: response.data.nickname.slice(0, 1),
      title: response.data.nickname,
    };
  });
  await fetchWarehouseDockDoors(route.params.id);
  if (route.query.tab) {
    tab.value = route.query.tab;
  }
};

watch(tab, (newValue: string) => {
  if (newValue) {
    const newUrl = `/warehouses/${route.params.id}?tab=${newValue}`;
    router.push(newUrl);
  }
});
</script>

<template>
  <div v-if="warehouse && warehouse.data" class="px-4">
    <div class="d-flex align-center">
      <v-avatar class="d-flex align-center mt-2" size="56"
        ><v-icon size="48" icon="mdi-warehouse"></v-icon
      ></v-avatar>
      <div class="w-100">
        <div class="d-flex align-center pb-0 justify-space-between">
          <v-card-title class="pb-0">
            {{ warehouse.data.name }} ({{ warehouse.data.address_entry?.full_address }})
          </v-card-title>
          <div class="d-flex align-center">
            <v-btn
              color="primary"
              :to="`/warehouses/${warehouse.data.id}/dashboard`"
              class="mr-2"
              >View Dashboard</v-btn
            >
          </div>
        </div>
        <v-card-subtitle class="mt-0">
          <span><strong>Code: </strong>{{ warehouse.data.code }}</span>
          <br />
          <span><strong>Tenant: </strong>{{ warehouse.data.tenant?.name }}</span>
        </v-card-subtitle>
      </div>
    </div>

    <div class="d-flex">
      <v-card flat class="mt-4 w-100 d-flex">
        <div class="w-100 px-90" v-if="dockDoors && dockDoors.data">
          <v-tabs v-model="tab" bg-color="primary">
            <v-tab value="map">Map</v-tab>
            <v-tab value="dockDoors">Dock Doors</v-tab>
            <v-tab value="closures">Closures</v-tab>
            <v-tab value="appointmentSlots">Appointment Slots</v-tab>
          </v-tabs>
          <v-window v-model="tab">
            <v-window-item value="map">
              <WarehouseMap
                v-if="warehouse && warehouse.data"
                :markerOptions="markerOptions"
                :center="center"
                :nickname="warehouse.data.nickname"
                :address="warehouse.data.address_entry?.full_address"
              />
            </v-window-item>

            <v-window-item value="dockDoors">
              <WarehouseDockDoorsTable
                v-if="dockDoors && dockDoors.data"
                :dockDoors="dockDoors"
                :deleteDockDoor="deleteDockDoor"
                :restoreDoor="restoreDockDoor"
              />
            </v-window-item>

            <v-window-item id="closures" value="closures">
              <WarehouseClosures />
            </v-window-item>

            <v-window-item value="appointmentSlots">
              <WarehouseAppointmentSlots />
            </v-window-item>
          </v-window>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style>
.vue-map-container {
  width: 100%;
  height: 100%;
}

/* Override tab transition */
.v-window-item {
  transition: none !important;
}
</style>
