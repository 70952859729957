<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { setPageTitle } from "@/utils/pageTitle";
// @ts-ignore
import useWarehouses from "@/hooks/useWarehouses";
// @ts-ignore
import { useRoute } from "vue-router/auto";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";

const {
  warehouse,
  fetchWarehouse,
  fetchWarehouseDockDoors,
  manageIbSlot,
  manageObSlot,
  transformSlotsData,
} = useWarehouses();
const route = useRoute();
const center = ref({});
const closures = ref({});
const ibToAdd = ref(null);
const obToAdd = ref(null);
const ibSlots = ref([]);
const obSlots = ref([]);
const closureOptions = ref(["ALL", ...ibSlots.value]);
const closureType = ref(); // Default to 'ib_slot'
const markerOptions = ref({});
const tab = ref("one");
const router = useRouter();
const userStore = useUserStore();

watch(closureType, (newValue) => {
  if (newValue == "ib_slot") {
    closureOptions.value = ["ALL", ...ibSlots.value];
  } else {
    closureOptions.value = ["ALL", ...obSlots.value];
  }
});

onMounted(async () => {
  await fetchAndSetWarehouseData();
});

const fetchAndSetWarehouseData = async () => {
  await fetchWarehouse(route.params.id).then((response: any) => {
    setPageTitle(
      `${response.data.nickname} (${response.data.address_entry?.full_address}) Warehouse`
    );
    center.value = {
      lat: response.data.address_entry?.latitude,
      lng: response.data.address_entry?.longitude,
    };
    markerOptions.value = {
      position: center.value,
      label: response.data.nickname.slice(0, 1),
      title: response.data.nickname,
    };
    ibSlots.value = response.data.appt_settings?.ib_slots?.sort();
    obSlots.value = response.data.appt_settings?.ob_slots?.sort();

    const transformedSlots = transformSlotsData(response.data.appt_settings?.closures);
    closures.value = transformedSlots;
  });
  await fetchWarehouseDockDoors(route.params.id);
  if (route.query.tab) {
    tab.value = route.query.tab;
  }
};

watch(
  () => warehouse.value,
  (newValue) => {
    if (newValue && newValue.data) {
      ibSlots.value = newValue.data.appt_settings?.ib_slots?.sort();
      obSlots.value = newValue.data.appt_settings?.ob_slots?.sort();
    }
  },
  { deep: true }
);

const deleteObSlot = async (slot: string) => {
  let params = {
    ob_slots_to_remove: [slot],
  };
  await manageObSlot(route.params.id, params);
  fetchWarehouse(route.params.id);
};

const deleteIbSlot = async (slot: string) => {
  let params = {
    ib_slots_to_remove: [slot],
  };
  await manageIbSlot(route.params.id, params);
  fetchWarehouse(route.params.id);
};

const addIbSlot = async () => {
  let params = {
    ib_slots_to_add: [ibToAdd.value],
  };
  await manageIbSlot(route.params.id, params);
  fetchWarehouse(route.params.id);
};

const addObSlot = async () => {
  let params = {
    ob_slots_to_add: [obToAdd.value],
  };
  await manageObSlot(route.params.id, params);
  fetchWarehouse(route.params.id);
};

watch(tab, (newValue) => {
  if (newValue) {
    const newUrl = `/warehouses/${route.params.id}?tab=${newValue}`;
    router.push(newUrl);
  }
});
</script>

<template>
  <v-card flat class="px-4 d-flex mt-4 justify-center">
    <v-list class="w-25 mr-8">
      <v-list-item-title class="font-weight-bold">Inbound Slots</v-list-item-title>
      <v-list-item v-for="n in ibSlots" :key="n" :title="n" v-if="ibSlots">
        <template v-slot:append>
          <v-btn
            color="error"
            icon="mdi-delete"
            variant="text"
            v-if="userStore.hasPermissionTo('update_warehouse')"
            @click="deleteIbSlot(n)"
          ></v-btn> </template
      ></v-list-item>

      <v-list-item v-if="userStore.hasPermissionTo('update_warehouse')">
        <v-text-field label="Add Inbound Slot (HH:MM)" v-model="ibToAdd" class="w-100" />
        <template v-slot:append>
          <v-btn
            color="success"
            icon="mdi-arrow-right"
            class="pb-3"
            variant="text"
            @click="addIbSlot"
          ></v-btn>
        </template>
      </v-list-item>
    </v-list>
    <v-list class="w-25 ml-8">
      <v-list-item-title class="font-weight-bold">Outbound Slots</v-list-item-title>
      <v-list-item v-for="n in obSlots" :key="n" :title="n" v-if="obSlots">
        <template v-slot:append>
          <v-btn
            color="error"
            icon="mdi-delete"
            variant="text"
            @click="deleteObSlot(n)"
            v-if="userStore.hasPermissionTo('update_warehouse')"
          ></v-btn> </template
      ></v-list-item>
      <v-list-item v-if="userStore.hasPermissionTo('update_warehouse')">
        <v-text-field label="Add Outbound Slot (HH:MM)" v-model="obToAdd" class="w-100" />
        <template v-slot:append>
          <v-btn
            color="success"
            icon="mdi-arrow-right"
            class="pb-3"
            variant="text"
            @click="addObSlot"
          ></v-btn>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
</template>
../../utils/pageTitle../../utils/pageTitle../../hooks/useWarehouses../../hooks/useWarehouses../../stores/user../../stores/user
